export function setUser(val) {
    return {
      type: 'SET_USER',
      val: val
    };
  }

  export function authHandle(val) {
    return {
      type: 'AUTH',
      val: val
    };
  }
  export function LoadStatus() {
    return {
      type: 'LOAD'
    };
  }

  export function clearToken() {
    return {
      type: 'CLEARSESSION'
    };
  }