import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  List,
  ListItemButton,
  Drawer,
  ListItem,
  ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { connect } from "react-redux";
import { setUser, authHandle, LoadStatus } from "./redux/action";
import Swal from "sweetalert2";
import {
  BrowserRouter,
  Route,
  Link,
  Switch as BasicSwitch,
  useHistory,
  useLocation,
} from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Home from "./com/Home";
import MyNote from "./com/MyNoteList";
import MyNoteMana from "./com/MyNoteManage";
import MyKeep from "./com/MyKeepList";
import MyKeepMana from "./com/MyKeepManage";

function App({ user, token, load, authHandle, setUser, LoadStatus }) {
  const [mod, setMod] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [regis, setRegis] = React.useState(false);
  const [userx, setUserx] = React.useState("");
  const [pass, setPass] = React.useState("");
  const locate = useLocation();

  React.useEffect(() => {
    if (mod == false) {
      setUserx("");
      setPass("");
    } else {
      setUserx(user);
    }
  }, [mod]);

  React.useEffect(() => {
    setOpen(false);
  }, [locate]);

  const register = (it, pass) => {
    setRegis(true);
    axios
      .request({
        method: "post",
        mode: "cors",
        maxBodyLength: Infinity,
        url: "https://mynotemykeepapi.azurewebsites.net/auth/register",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({
          userName: it,
          pass: btoa(pass)
        }),
      })
      .then(function (result) {
        // handle success
        setRegis(false);
        if (result.data.status) {
          setUser(result.data.userName);
          localStorage.setItem("user", result.data.userName);
          if (result.data.message == undefined) {
            Swal.fire({
              title: "Register complete",
              html: "Please use this username <b>" + it + "</b> to authen",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "This user is already registered",
              text: "You can login with this username",
              icon: "info",
            });
          }
        } else {
          setRegis(false);
          Swal.fire({
            title: "Error found",
            text: result.data.message,
            icon: "error",
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };
  const Login = () => {
    const userdata = userx;
    const passx = pass;
    setMod(false);
    LoadStatus();
    axios
      .post(
        "https://mynotemykeepapi.azurewebsites.net/auth/token",
        {
          userName: userx,
          pass: btoa(passx)
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (result) {
        // handle success
        LoadStatus();
        if (result.data.status) {
          setUser(userdata);
          authHandle(result.data.access_token);
          sessionStorage.setItem("tk", result.data.access_token)
          if (
            localStorage.getItem("user") == null ||
            (localStorage.getItem("user") != null &&
              localStorage.getItem("user") != userdata)
          ) {
            localStorage.setItem("user", userdata);
          }
        } else {
          authHandle("");
          Swal.fire({
            title: "Error found",
            text: result.data.message,
            icon: "error",
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
        LoadStatus();
        authHandle("");
        Swal.fire({
          title: "Error found",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            My Note and My Keep
          </Typography>
          {load ? (
            <CircularProgress className="text-light" />
          ) : (
            <IconButton onClick={() => setMod(true)} className="text-light">
              {token != "" ? <VerifiedUserIcon /> : <KeyIcon />}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={() => setOpen(false)}>
        <List sx={{ width: 200 }}>
          <ListItem
            button
            component={Link}
            to="/"
            className={
              locate.pathname == "/"
                ? "border-primary border-left border-left"
                : ""
            }
            sx={{ borderWidth: 50 }}>
            <ListItemText primary={"Home"} />
          </ListItem>
          {token != "" && (
            <>
              <ListItem
                button
                component={Link}
                to="/mynote"
                className={
                  locate.pathname.includes("/mynote")
                    ? "border-primary border-left"
                    : ""
                }
                sx={{ borderWidth: 50 }}>
                <ListItemText primary={"My Note"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/mykeep"
                className={
                  locate.pathname.includes("/mykeep")
                    ? "border-primary border-left"
                    : ""
                }
                sx={{ borderWidth: 50 }}>
                <ListItemText primary={"My Keep"} />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>

      <div style={{ marginTop: 100, marginBottom: 80 }}>
        <BasicSwitch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/mynote" render={() => <MyNote />} />
          <Route
            exact
            path="/mynote/manage/:id?"
            render={() => <MyNoteMana />}
          />
          <Route exact path="/mykeep" render={() => <MyKeep />} />
          <Route
            exact
            path="/mykeep/manage/:id?"
            render={() => <MyKeepMana />}
          />
        </BasicSwitch>
      </div>

      <Dialog
        open={mod}
        onClose={() => (regis == false ? setMod(false) : null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Authentication"}</DialogTitle>
        <DialogContent>
          {token != "" ? (
            <>
              <TextField
                value={user != undefined ? user : ""}
                required
                fullWidth={true}
                label="Enter username"
                variant="standard"
              />
              <TextField
                value={token}
                required
                fullWidth={true}
                type="text"
                label="Access Token"
                variant="standard"
                className="mt-2"
              />
            </>
          ) : (
            <>
              <TextField
                onKeyUp={(e) => token == "" && setUserx(e.target.value)}
                defaultValue={user != undefined ? user : ""}
                required
                label="Enter username"
                variant="standard"
                fullWidth={true}
                disabled={load}
              />
              <TextField
                onKeyUp={(e) => token == "" && setPass(e.target.value)}
                defaultValue={pass}
                required
                className="mt-2"
                type="password"
                label="Enter password"
                variant="standard"
                fullWidth={true}
                disabled={load}
              />
            </>
          )}
        </DialogContent>
        {token == "" && (
          <DialogActions>
            <Button
              disabled={regis}
              onClick={() => {
                if (userx == "") {
                  return;
                }
                setUser(userx);
                Login();
              }}
              autoFocus>
              Login
            </Button>
            <Button disabled={regis} onClick={() => register(userx, pass)}>
              Register
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  token: state.token,
  load: state.load,
});
const mapDispatchToProps = (dispatch) => ({
  setUser: (val) => dispatch(setUser(val)),
  authHandle: (val) => dispatch(authHandle(val)),
  LoadStatus: () => dispatch(LoadStatus()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
