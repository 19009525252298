import React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  List,
  ListItemButton,
  Drawer,
  ListItem,
  Card,
  Box,
  CardContent,
  Backdrop,
  CardActions,
} from "@mui/material";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { clearToken } from "../redux/action";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";

const NoteManage = ({ clearToken, token }) => {
  const His = useHistory();
  const { id } = useParams();
  const [load, setLoad] = React.useState(true);
  const [data, setData] = React.useState({
    head: "",
    desc: EditorState.createEmpty(),
  });

  React.useEffect(() => {
    if (id != undefined) {
      setLoad(true)
      axios
        .request({
          method: "get",
          mode: "cors",
          maxBodyLength: Infinity,
          url: "https://mynotemykeepapi.azurewebsites.net/mynote/" + id,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "bearer " + token,
          },
        })
        .then(function (result) {
          // handle success
          setTimeout(() => {
            setLoad(false);
          }, 500);
          if (result.data.status == true) {
            setData({
              ...data,
              head: result.data.response.noteHeader,
              desc: EditorState.createWithContent(
                stateFromHTML(result.data.response.noteDesc)
              ),
            });
          } else {
            Swal.fire({
              title: "Error found",
              text: result.data.message,
              icon: "error",
            });
          }
        })
        .catch(function (error) {
          setLoad(false);
          if (error.response.status == 401) {
            Swal.fire({
              title: "Error found",
              text: "Token expired",
              icon: "error",
            });
            clearToken();
          } else {
            Swal.fire({
              title: "Error found",
              text: error.response.data.message,
              icon: "error",
            });
          }
          console.log("error", error);
        });
    } else {
      setLoad(false);
    }
  }, [token]);

  const getDataDesc = (obj) => {
    return draftToHtml(convertToRaw(obj.getCurrentContent()));
  };

  const saveHandle = () => {
    if (data.head == "" || data.desc.getCurrentContent().hasText() == false) {
      Swal.fire({
        title: "Error found",
        text: "Data empty",
        icon: "warning",
      });
      return;
    }
    const desc = getDataDesc(data.desc);
    setLoad(true);
    axios
      .request({
        method: id != undefined ? "put" : "post",
        mode: "cors",
        maxBodyLength: Infinity,
        url:
          "https://mynotemykeepapi.azurewebsites.net/mynote" +
          (id != undefined ? "/" + id : ""),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "bearer " + token,
        },
        data: JSON.stringify({
          noteHeader: data.head,
          noteDesc: desc,
        }),
      })
      .then(function (result) {
        // handle success
        setLoad(false);
        if (result.data.status == true) {
          His.push("/mynote");
        } else {
          Swal.fire({
            title: "Error found",
            text: result.data.message,
            icon: "error",
          });
        }
      })
      .catch(function (error) {
        setLoad(false);
        if (error.response.status == 401) {
          Swal.fire({
            title: "Error found",
            text: "Token expired",
            icon: "error",
          });
          clearToken();
        } else {
          Swal.fire({
            title: "Error found",
            text: error.response.data.message,
            icon: "error",
          });
        }
        console.log("error", error);
      });
  };

  return (
    <Box className="container">
      <Card>
        <CardContent sx={{ marginBottom: 5 }}>
          <TextField
            label="Note Header"
            value={data.head}
            onChange={(e) =>
              setData({
                ...data,
                head: e.target.value,
              })
            }
            type="text"
            fullWidth={true}
          />
          <div className="mt-5">
            <Editor
              editorState={data.desc}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) =>
                setData({
                  ...data,
                  desc: e,
                })
              }
            />
          </div>
        </CardContent>
        {token != "" && (
          <CardActions className="p-3">
            <Button variant="contained" onClick={() => saveHandle()}>
              Save
            </Button>
            <Button
              variant="outlined"
              className="text-info border-info"
              onClick={() => His.push("/mynote")}>
              Back
            </Button>
          </CardActions>
        )}
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
});
const mapDispatchToProps = (dispatch) => ({
  clearToken: () => dispatch(clearToken()),
});
export default connect(mapStateToProps, mapDispatchToProps)(NoteManage);
