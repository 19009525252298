import React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Button,
  TextField,
  CardHeader,
  ListItemButton,
  Drawer,
  CardActions,
  Card,
  Typography,
  Grid,
  Backdrop,
  Box,
  CardContent,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { clearToken } from "../redux/action";
import AddIcon from "@mui/icons-material/Add";

const NoteList = ({ token, clearToken }) => {
  const [note, setNote] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const His = useHistory();

  const Fetch = () => {
    setLoad(true);
    axios
      .request({
        method: "get",
        mode: "cors",
        maxBodyLength: Infinity,
        url: "https://mynotemykeepapi.azurewebsites.net/mynote",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "bearer " + token,
        },
      })
      .then(function (result) {
        // handle success
        setTimeout(() => {
          setLoad(false);
        }, 500);
        if (result.data.status) {
          setNote(result.data.responses);
        } else {
          Swal.fire({
            title: "Error found",
            text: result.data.message,
            icon: "error",
          });
        }
      })
      .catch(function (error) {
        setLoad(false);
        if (error.response.status == 401) {
          Swal.fire({
            title: "Error found",
            text: "Token expired",
            icon: "error",
          });
          clearToken();
        } else {
          Swal.fire({
            title: "Error found",
            text: error.response.data.message,
            icon: "error",
          });
        }
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    if (token == "") {
      setLoad(false);
      return;
    }
    Fetch();
  }, [token]);

  const DeleteHandle = (id) => {
    if (token == "") {
      setLoad(false);
      return;
    }

    Swal.fire({
      title: "Do you want to delete this Note?",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoad(true);
        axios
          .request({
            method: "delete",
            mode: "cors",
            maxBodyLength: Infinity,
            url: "https://mynotemykeepapi.azurewebsites.net/mynote/" + id,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "bearer " + token,
            },
          })
          .then(function (result) {
            // handle success
            setLoad(false);
            if (result.status == 204) {
              setLoad(true);
              Fetch();
            } else {
              Swal.fire({
                title: "Error found",
                text: result.data.message,
                icon: "error",
              });
            }
          })
          .catch(function (error) {
            setLoad(false);
            if (error.response.status == 401) {
              Swal.fire({
                title: "Error found",
                text: "Token expired",
                icon: "error",
              });
              clearToken();
            } else {
              Swal.fire({
                title: "Error found",
                text: error.response.data.message,
                icon: "error",
              });
            }
            console.log("error", error);
          });
      }
    });
  };

  return (
    <Box className="container">
      <Grid container spacing={1}>
        {note.map((itemx) => (
          <Grid key={itemx.noteId} item xs={12} md={4}>
            <Card>
              <CardHeader
                title={itemx.noteHeader}
                subheader={
                  "Updated in " +
                  moment(itemx.noteUpdated).local().format("DD MMMM YYYY HH:mm")
                }
              />
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => His.push("/mynote/manage/" + itemx.noteId)}>
                  Edit this Note
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  className="text-danger border-danger"
                  onClick={() => DeleteHandle(itemx.noteId)}>
                  Delete it!
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {!load && note.length == 0 && (
        <Grid component={Card} xs={12}>
          <CardContent className="text-center">
            <Typography>My Note for you are not found</Typography>
            {token != "" && (
              <Button
                onClick={() => His.push("/mynote/manage")}
                className="mt-3"
                variant="outlined">
                Add new One
              </Button>
            )}
          </CardContent>
        </Grid>
      )}

      {token != "" && !load && note.length > 0 && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => His.push("/mynote/manage")}
          sx={{ position: "fixed", zIndex: 100, bottom: 5, right: 5 }}>
          <AddIcon />
        </Fab>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  token: state.token,
});
const mapDispatchToProps = (dispatch) => ({
  clearToken: () => dispatch(clearToken()),
});
export default connect(mapStateToProps, mapDispatchToProps)(NoteList);
