import React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  List,
  ListItemButton,
  Drawer,
  ListItem,
  Typography,
  Box,
} from "@mui/material";

const Home = () => {
  return (
    <Box className='container'>
      <Typography variant="h4">My Note and My Keep</Typography>
      <Typography variant="h6" className="mt-5">What is My Note?</Typography>
      <Typography variant="p">My Note is free simple online note platform for everyone.</Typography>
      <Typography variant="h6" className="mt-5">What is My Keep?</Typography>
      <Typography variant="p">My Keep is free simple unstructured object platform for everyone.</Typography>
      <Typography variant="h6" className="mt-5">The first MERN Stack with MySQL Project of me</Typography>
      <Typography variant="p">MERN Stack (MongoDB, Express, React, Node) is powerful stack technology to help great system experience.</Typography>
      <Typography variant="h6" className="mt-5">API Support</Typography>
      <Typography variant="p">API System use Node.JS Express JS API.</Typography>
      <Typography variant="h6" className="mt-5">Free Platform for everyone</Typography>
      <Typography variant="p">Anyone can use this platform.</Typography>
    </Box>
  );
};

export default Home;
