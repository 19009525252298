import axios from "axios";

const initialState = {
  user: localStorage.getItem("user"),
  load: false,
  token: sessionStorage.getItem("tk") != null ? sessionStorage.getItem("tk") : "",
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.val };
    case "LOAD":
      return { ...state, load: !state.load };
    case "AUTH":
      return { ...state, token: action.val };
    case "CLEARSESSION":
      sessionStorage.removeItem("tk")
      return { ...state, token: '' };
    default:
      return state;
  }
}

export default Reducer;
